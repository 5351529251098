import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component'
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {ButtonModule} from 'primeng/button';
import {Toolbar} from 'primeng/toolbar';
import {PublicDashboardComponent} from './public-dashboard/public-dashboard.component';
import {OAuthModule} from 'angular-oauth2-oidc';
import {HttpInterceptorService} from './modules/shared/services/interceptor/http-interceptor.service';
import {BadgeModule} from 'primeng/badge';
import {LoginModule} from './modules/login/login.module';
import {DatePipe} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {DividerModule} from 'primeng/divider';
import {InputTextModule} from 'primeng/inputtext';
import {SharedModule} from './modules/shared/shared.module';

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    Toolbar,
    PublicDashboardComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    OAuthModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    LoginModule,
    ButtonModule,
    BadgeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastModule,
    DividerModule,
    InputTextModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    DatePipe,
    HttpClient,
    TranslateModule,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
