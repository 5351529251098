import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective {

  private scrollThreshold = 555; // px

  constructor(private element: ElementRef) {}

  @Output() scrollEvent: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('scroll')
  public onScroll() {
    const scrolled = this.element.nativeElement.scrollTop;
    const height = this.element.nativeElement.offsetHeight;

    // if we have reached the threshold and we scroll down
    if (height - scrolled < this.scrollThreshold) {
      this.scrollEvent.emit();
    }

  }

}
