export interface AvatarResources {
  label: string;
  src: string
}

export const AVATAR_RESOURCES_LIST: Array<AvatarResources> = [
  {
    label: 'african_male_kid',
    src: '/../../../assets/images/avatars/african_male_kid.png'
  },
  {
    label: 'african_male_youth',
    src: '/../../../assets/images/avatars/african_male_youth.png'
  },
  {
    label: 'african_male_teenager',
    src: '/../../../assets/images/avatars/african_male_teenager.png'
  },
  {
    label: 'african_male_adult',
    src: '/../../../assets/images/avatars/african_male_adult.png'
  },
  {
    label: 'african_female_kid',
    src: '/../../../assets/images/avatars/african_female_kid.png'
  },
  {
    label: 'african_female_youth',
    src: '/../../../assets/images/avatars/african_female_youth.png'
  },
  {
    label: 'african_female_teenager',
    src: '/../../../assets/images/avatars/african_female_teenager.png'
  },
  {
    label: 'african_female_adult',
    src: '/../../../assets/images/avatars/african_female_adult.png'
  },
  {
    label: 'asian_male_kid',
    src: '/../../../assets/images/avatars/asian_male_kid.png'
  },
  {
    label: 'asian_male_youth',
    src: '/../../../assets/images/avatars/asian_male_youth.png'
  },
  {
    label: 'asian_male_teenager',
    src: '/../../../assets/images/avatars/asian_male_teenager.png'
  },
  {
    label: 'asian_male_adult',
    src: '/../../../assets/images/avatars/asian_male_adult.png'
  },
  {
    label: 'asian_female_kid',
    src: '/../../../assets/images/avatars/asian_female_kid.png'
  },
  {
    label: 'asian_female_youth',
    src: '/../../../assets/images/avatars/asian_female_youth.png'
  },
  {
    label: 'asian_female_teenager',
    src: '/../../../assets/images/avatars/asian_female_teenager.png'
  },
  {
    label: 'asian_female_adult',
    src: '/../../../assets/images/avatars/asian_female_adult.png'
  },
  {
    label: 'european_male_kid',
    src: '/../../../assets/images/avatars/european_male_kid.png'
  },
  {
    label: 'european_male_youth',
    src: '/../../../assets/images/avatars/european_male_youth.png'
  },
  {
    label: 'european_male_teenager',
    src: '/../../../assets/images/avatars/european_male_teenager.png'
  },
  {
    label: 'european_male_adult',
    src: '/../../../assets/images/avatars/european_male_adult.png'
  },
  {
    label: 'european_female_kid',
    src: '/../../../assets/images/avatars/european_female_kid.png'
  },
  {
    label: 'european_female_youth',
    src: '/../../../assets/images/avatars/european_female_youth.png'
  },
  {
    label: 'european_female_teenager',
    src: '/../../../assets/images/avatars/european_female_teenager.png'
  },
  {
    label: 'european_female_adult',
    src: '/../../../assets/images/avatars/european_female_adult.png'
  },
  {
    label: 'russian_male_kid',
    src: '/../../../assets/images/avatars/russian_male_kid.png'
  },
  {
    label: 'russian_male_youth',
    src: '/../../../assets/images/avatars/russian_male_youth.png'
  },
  {
    label: 'russian_male_teenager',
    src: '/../../../assets/images/avatars/russian_male_teenager.png'
  },
  {
    label: 'russian_male_adult',
    src: '/../../../assets/images/avatars/russian_male_adult.png'
  },
  {
    label: 'russian_female_kid',
    src: '/../../../assets/images/avatars/russian_female_kid.png'
  },
  {
    label: 'russian_female_youth',
    src: '/../../../assets/images/avatars/russian_female_youth.png'
  },
  {
    label: 'russian_female_teenager',
    src: '/../../../assets/images/avatars/russian_female_teenager.png'
  },
  {
    label: 'russian_female_adult',
    src: '/../../../assets/images/avatars/russian_female_adult.png'
  }
]


export function getAvatarUrl(label: string) {
  return AVATAR_RESOURCES_LIST.find(r => r.label === label)?.src;
}
