import { Injectable } from '@angular/core';
import { UserDto } from '../../../../swagger/generated/models/user-dto';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  constructor(private router: Router) {}

  setAccessToken(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  setConnectedUser(userDTO: UserDto): void {
    localStorage.setItem('connectedUser', JSON.stringify(userDTO));
  }

  setCurrentTheme(themName: string) {
    localStorage.setItem('theme', themName);
  }

  setCurrentThemeBaseUrl(themUrl: string) {
    localStorage.setItem('theme-url', themUrl);
  }

  getCurrentThemeBaseUrl() {
    return localStorage.getItem('theme-url') || 'https://d2hr90nxl1wnyh.cloudfront.net/themes/basic';
  }

  setAnonymousUser(userName: string, selectedAvatar) {
    let anonymousCredentials = { 'displayName': userName, 'avatar': selectedAvatar};
    localStorage.setItem('anonymousUser',JSON.stringify(anonymousCredentials));
  }

  getAnonymousUserIfExists() {
    if (localStorage.getItem('anonymousUser')) {
      return {
        displayName: localStorage.getItem('anonymousUser'),
        isAnonymous: true
      }
    }
    return {
      displayName: null,
      isAnonymous: false
    }
  }

  getConnectedUser(): any {
    if (localStorage.getItem('connectedUser')) {
      return JSON.parse(localStorage.getItem('connectedUser'));
    } else if (localStorage.getItem('anonymousUser')) {
      return  JSON.parse(localStorage.getItem('anonymousUser'));
    }
    return {};
  }

  async logout() {
    localStorage.clear();
    await this.router.navigate(['/home']);
  }
}
