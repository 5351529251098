import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoaderService} from '../../components/loader/service/loader.service';
import {Router} from '@angular/router';
import {ThemeManagerService} from '../theme-service/theme-manager.service';
import {UserStoreService} from '../../stores/user-store/user-store.service';
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor{
  private homeUrl = environment.homeUrl;
  constructor(
    private loaderService: LoaderService,
    private themeManager: ThemeManagerService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.themeManager.setStyleOrElseFallback();
    this.loaderService.show();
    if (!req.url.includes('auth/authenticate')){
      let accessToken: string;
      if (localStorage.getItem('accessToken')) {
        accessToken = localStorage.getItem('accessToken') as string
        const authReq = req.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + accessToken
          })
        });
        return this.handleRequest(authReq, next);
      }
    }
    return this.handleRequest(req, next);
  }

  handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
    .pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.loaderService.hide();
      }
    }, (error) => {
      if (error.status === 0) {
        this.router.navigate(['/error']);
        // this.router.navigate([this.homeUrl]);
      }
      this.loaderService.hide();
    }));
  }
}
