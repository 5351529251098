import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CommonUtilsService} from '../common-utils/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class TokenUtilsService {

  private jwtHelper = new JwtHelperService();
  constructor(
    private commonUtils: CommonUtilsService,
  ) { }

  isTokenValid(token?: string): boolean {
    if (this.commonUtils.isNotEmptyOrNull(token)) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    if (this.commonUtils.isNotEmptyOrNull(localStorage.getItem('accessToken'))) {
      return !this.jwtHelper.isTokenExpired(localStorage.getItem('accessToken'));
    }
    return false;
  }
}
