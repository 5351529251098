import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation-registration',
  templateUrl: './confirmation-registration.component.html',
  styleUrls: ['./confirmation-registration.component.scss'],
})
export class ConfirmationRegistrationComponent implements OnInit {
  codeConfirmation: any;

  constructor(
    // private route: ActivatedRoute,
    // private loginService: LoginService
  ) {}

  ngOnInit(): void {
    // this.codeConfirmation = this.route.snapshot.params.codeConfirmation;
    // this.loginService.enableUser(this.codeConfirmation).subscribe(
    //   (resp) => {
    //     console.log(resp);
    //   },
    //   (err) => {
    //     console.log(err.error.message);
    //   }
    // );
  }
}
