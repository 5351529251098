import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// dynamically inject baseHref html tag as it's not picked up from environment baseHref!
function setBaseHref(): void {
  const baseHref = window.location.pathname;

  // Adjust the baseHref logic as needed
  const baseElement = document.createElement('base');
  baseElement.href = baseHref;
  document.head.appendChild(baseElement);
}

setBaseHref();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
