import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error5xx',
  templateUrl: './error5xx.component.html',
  styleUrls: ['./error5xx.component.scss']
})
export class Error5xxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
