import {Component, OnInit} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {UserDto} from '../../../../../swagger/generated/models/user-dto';
import {AuthenticationService} from '../../../../../swagger/generated/services/authentication.service';
import {UserStoreService} from '../../../stores/user-store/user-store.service';
import {UserService} from '../../../../../swagger/generated/services/user.service';
import {DatePipe} from '@angular/common';
import {ChangeUserPasswordDto} from '../../../../../swagger/generated/models/change-user-password-dto';
// import {AVATAR_RESOURCES_LIST, AvatarResources, getAvatarUrl} from '../../../../login/registration/models/avatar-resources';
import {NotificationService} from '../../../services/notification/notification.service';
// import {AvatarsContainerComponent} from '../../../../login/registration/avatars-container/avatars-container.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-manage-user-profil',
  templateUrl: './manage-user-profil.component.html',
  styleUrls: ['./manage-user-profil.component.scss'],
  providers: [ConfirmationService]

})
export class ManageUserProfilComponent implements OnInit {
  userDto: UserDto = {};
  changePasswordDto: ChangeUserPasswordDto = {};
  errorMessages = '';
  birthDate: Date;
  securityAndLogin = false;
  // selectedAvatar: AvatarResources;
  // avatarsList = AVATAR_RESOURCES_LIST

  constructor(
    private authenticationService: AuthenticationService,
    private userStore: UserStoreService,
    private userService: UserService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
  ) {
  }

  ngOnInit(): void {
    this.userService.get(this.userStore.getConnectedUser().id)
    .subscribe((res) => {
      this.userDto = res;
      this.birthDate = this.transform(this.userDto.birthDate);
      // this.selectedAvatar = {
      //   label: res.avatar,
      //   src: getAvatarUrl(res.avatar)
      // }
    });
  }

  updateProfile() {
    // this.userDto.avatar = this.selectedAvatar.label;
    this.userService.save(this.userDto)
    .subscribe(res => {
      this.userDto = res;
      this.userStore.setConnectedUser(this.userDto);
      this.notificationService.success({
        summary: 'Profile successfully updated'
      });
    });
  }

  onChangeBirthdate(event) {
    this.userDto.birthDate = this.datePipe.transform(event, 'dd.MM.yyyy');
  }

  changePassword() {
    this.changePasswordDto.idUser = this.userDto.id;
    this.userService.changePassword(this.changePasswordDto)
    .subscribe(res => {
      this.notificationService.success({
        summary: 'Password successfully updated'
      });
    });

  }

  transform(value: string): Date {
    if (value != null && value !== '') {
      const day = value.toString().substring(0, 2);
      const month = value.toString().substring(3, 5);
      return new Date(day + '.' + month + '.' + value.toString().substring(6, 10));
    }
    return null;
  }

  showSecurityAndLogin() {
    this.securityAndLogin = true;
    this.changePasswordDto = {};
  }

  showGeneraSetting() {
    this.securityAndLogin = false;
  }
  // displayAvatars() {
  //   this.ref = this.dialogService.open(AvatarsContainerComponent, {
  //       header: 'Choose your Avatar',
  //       width: '30%',
  //       data: {
  //       }
  //     }
  //   );
  //   this.ref.onClose.subscribe((avatar: AvatarResources) => {
  //     if (avatar) {
  //       this.selectedAvatar = avatar;
  //     }
  //   });
  // }
}
