import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {ManageUserProfilComponent} from './components/pages/manage-user-profil/manage-user-profil.component';
import {LoaderComponent} from './components/loader/loader.component';
import {LogoutComponent} from './components/pages/logout/logout.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {FormsModule} from '@angular/forms';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PasswordModule} from 'primeng/password';
import {MultiSelectModule} from 'primeng/multiselect';
import {DataViewModule} from 'primeng/dataview';
import {TranslateModule} from '@ngx-translate/core';
import {FileUploadModule} from 'primeng/fileupload';
import {DividerModule} from 'primeng/divider';
import {TabViewModule} from 'primeng/tabview';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {FieldsetModule} from 'primeng/fieldset';
import {InfiniteScrollDirective} from './directives/InfiniteScrollDirective';
import {ToastModule} from 'primeng/toast';
import {PanelModule} from 'primeng/panel';
import {MessageModule} from 'primeng/message';
import {CheckboxModule} from 'primeng/checkbox';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SliderModule} from 'primeng/slider';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {Error5xxComponent} from './components/pages/error-pages/error5xx/error5xx.component';
import {Error404Component} from './components/pages/error-pages/error404/error404.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RouterModule} from '@angular/router';
import {SafeUrlPipe} from './services/utils/pipes/safe-url.pipe';
import {CarouselModule} from 'primeng/carousel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TreeModule} from 'primeng/tree';
import {RatingModule} from "primeng/rating";


@NgModule({
  declarations: [
    LoaderComponent,
    LogoutComponent,
    ManageUserProfilComponent,
    InfiniteScrollDirective,
    SafeUrlPipe,
    Error5xxComponent,
    Error404Component,
  ],
  imports: [
    CommonModule,
    TableModule,
    SplitButtonModule,
    FormsModule,
    CardModule,
    DropdownModule,
    InputTextModule,
    CalendarModule,
    ConfirmDialogModule,
    PasswordModule,
    MultiSelectModule,
    DataViewModule,
    TranslateModule,
    DividerModule,
    TabViewModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    FieldsetModule,
    ToastModule,
    PanelModule,
    MessageModule,
    CheckboxModule,
    DynamicDialogModule,
    DialogModule,
    ColorPickerModule,
    ButtonModule,
    RippleModule,
    InputTextareaModule,
    SliderModule,
    ProgressBarModule,
    TooltipModule,
    RadioButtonModule,
    RouterModule,
    CarouselModule,
    InputSwitchModule,
    TreeModule,
    RatingModule
  ],
  exports: [
    LoaderComponent,
    LogoutComponent,
    ManageUserProfilComponent,
    SplitButtonModule,
    DataViewModule,
    TranslateModule,
    FileUploadModule,
    InputTextModule,
    ToastModule,
    PanelModule,
    MessageModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    PasswordModule,
    DynamicDialogModule,
    DialogModule,
    ColorPickerModule,
    ButtonModule,
    RippleModule,
    SafeUrlPipe,
    CardModule,
    InfiniteScrollDirective,
    ProgressBarModule,
  ]

})
export class SharedModule {
}
