import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule, Routes} from '@angular/router';
import {PublicDashboardComponent} from './public-dashboard/public-dashboard.component';
import {RegistrationComponent} from './modules/login/registration/registration.component';
import {ConfirmationRegistrationComponent} from './modules/login/confirmation-registration/confirmation-registration.component';
import {Error5xxComponent} from './modules/shared/components/pages/error-pages/error5xx/error5xx.component';
import {Error404Component} from './modules/shared/components/pages/error-pages/error404/error404.component';

const routes: Routes = [

  {
    path: '',
    component: PublicDashboardComponent
  },
  {
    path: 'home',
    component: PublicDashboardComponent
  },
  {
    path: 'login',
    component: RegistrationComponent
  },
  {
    path: 'error',
    component: Error5xxComponent
  },
  {
    path: '404',
    component: Error404Component
  },
  {
    path: 'confirmation/:codeConfirmation',
    component: ConfirmationRegistrationComponent,
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then(
        (module) => module.UserModule
      )
  },

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
})
export class AppRoutingModule {}
