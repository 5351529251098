import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({name: 'SafeUrl'})
export class SafeUrlPipe implements PipeTransform {
  constructor(
    private readonly sanitizer: DomSanitizer
  ) {
  }

  public transform(url: SafeResourceUrl): SafeResourceUrl {
    // @ts-ignore
    return this.sanitizer.bypassSecurityTrustResourceUrl(url.changingThisBreaksApplicationSecurity);
  }
}
