import {Component, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {NotificationService} from './modules/shared/services/notification/notification.service';
import {MessageService} from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent implements OnInit, OnDestroy {

  private subscription: Subscription;


  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private messageService: MessageService
  ) {
    translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.subscription = this.notificationService.notificationState.subscribe((notification) => {
      this.messageService.add(notification);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
