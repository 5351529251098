import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistrationComponent} from './registration/registration.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {ConfirmationRegistrationComponent} from './confirmation-registration/confirmation-registration.component';
import {ButtonModule} from 'primeng/button';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {SharedModule} from '../shared/shared.module';
import {AvatarsContainerComponent} from "./registration/avatars-container/avatars-container.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {CarouselModule} from 'primeng/carousel';

@NgModule({
  declarations: [
    RegistrationComponent,
    ConfirmationRegistrationComponent,
    AvatarsContainerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    MessagesModule,
    MessageModule,
    SharedModule
  ],
  providers: [
    DialogService,
    DynamicDialogRef
  ],
  exports: [
    RegistrationComponent,
    AvatarsContainerComponent
  ]
})
export class LoginModule {
}
