import {Injectable} from '@angular/core';
import {getAvatarUrl} from '../../../../login/registration/models/avatar-resources';
import {UserStoreService} from '../../../stores/user-store/user-store.service';

const ERROR = 'error';
const SUCCESS = 'success';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {
  constructor(private userStore: UserStoreService) {
  }

  convertDataUrlToBlob(dataUrl): Blob {
    if (this.isEmptyOrNull(dataUrl)) {
      return undefined;
    }
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
  }

  createObjectURL(dataUrl: string) {
    return URL.createObjectURL(this.convertDataUrlToBlob(dataUrl));
  }


  playAudio(audioUrl: string): HTMLAudioElement {
    if (this.isNotEmptyOrNull(audioUrl)) {
      const audio = new Audio();
      audio.src = audioUrl;
      audio.load();
      audio.play();
      return audio;
    }
    return undefined;
  }

  stopAudio(audio: HTMLAudioElement): void {
    let isStopped = false;
    if (this.isNotEmptyOrNull(audio)) {
      audio.pause();
      isStopped = true;
    }
  }

  toRelativeValue(position: number, size: number): number {
    if (!position || !size) {
      return 0;
    }
    if (size < 0) {
      // tslint:disable-next-line:no-console
      console.error('the size should not be a negatif value');
    }
    if (position < 0) {
      // tslint:disable-next-line:no-console
      console.error('the position should not be a negatif value');
    }
    return Math.floor(position * size);
  }

  toPercentValue(position: number, size: number): number {
    if (!position) {
      return 0;
    }
    if (size < 0) {
      // tslint:disable-next-line:no-console
      console.error('the size should not be a negatif value');
    }
    if (position < 0) {
      // tslint:disable-next-line:no-console
      console.error('the position should not be a negatif value');
    }
    if (size === 0) {
      // tslint:disable-next-line:no-console
      console.error('the denominator contains value zero');
      return 0;
    }
    return position / size;
  }

  isEmptyOrNull(value: any): boolean {
    return value === undefined || value === null || value.length === 0;
  }

  isNotEmptyOrNull(value: any): boolean {
    return !this.isEmptyOrNull(value);
  }

  getBase64ImageFromUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  shuffleArray = function (array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

  detectUser() {
    if (this.userStore.getConnectedUser().id) {
      return {
        'userName': this.userStore.getConnectedUser().displayName,
        'avatar': getAvatarUrl(this.userStore.getConnectedUser().avatar)
      };
    } else {
      return localStorage.getItem('anonymousCredentials');
    }
  }

  randomID() {
    return Math.random().toString(36).substr(2, 9);
  };

  downloadBlob(url) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  transformMonthYear(monthYearDate) {
    const date = monthYearDate.split("-");
    let year = date[0];
    let month = date[1];
    return month + "." + year;
  }

  combineDateTime(dateVar, timeVar) {
    if (dateVar && timeVar) {
      const date = new Date(dateVar);
      const timeParts = timeVar.split(':');
      date.setHours(Number(timeParts[0]));
      date.setMinutes(Number(timeParts[1]));
      return date
    }
  }

  formatDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  areTwoObjectsEqual(obj1: any, obj2: any): boolean {
    const keys = Object.keys(obj1) as Array<keyof any>;
    for (const key of keys) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  validateFirstName(args) {
    var value = args.value || "";
    if (value.trim().length === 0) {
      args.valid = false;
      args.message = "FirstName is required";
    }
    if(value && value[0].toUpperCase() != value[0]){
      args.valid = false;
      args.message = "The first letter should be upper case";
    }
  }

  validateLastName(args) {
    var value = args.value || "";
    if (value.trim().length === 0) {
      args.valid = false;
      args.message = "LastName is required";
    }
    if(value && value[0].toUpperCase() != value[0]){
      args.valid = false;
      args.message = "The first letter should be upper case";
    }
  }

  validateEmail(args) {
    var value = args.value || "";
    if (value.trim().length === 0) {
      args.valid = false;
      args.message = "Email is required";
    }
    if(args.valid){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(value)){
        args.valid = false;
        args.message = "Please check your email address";
      }
    }
  }

  validateTel(args) {
    var value = args.value || "";
    if (value.trim().length === 0) {
      args.valid = false;
      args.message = "Phone number is required";
    }
    if(args.valid){
      const phoneRegex = /^\+?[\d\s\-().]{7,15}$/;
      if(!phoneRegex.test(value)){
        args.valid = false;
        args.message = "Please check your phone number";
      }
    }
  }
}
