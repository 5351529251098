/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {ApiConfiguration as __Configuration} from '../api-configuration';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';
import {Observable as __Observable} from 'rxjs';
import {filter as __filter, map as __map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class UploadService extends __BaseService {
  static readonly createFolderPath = '/api/v1/upload/createfolder';
  static readonly createSubFolderPath = '/api/v1/upload/createsubfolder';
  static readonly deleteFilePath = '/api/v1/upload/deletefile/{fileId}';
  static readonly doGoogleSignInPath = '/api/v1/upload/googlesignin';
  static readonly saveAuthorizationCodePath = '/api/v1/upload/oauth';
  static readonly uploadPath = '/api/v1/upload/upload/{subFolderId}/{newFileName}/{locationFile}';
  static readonly getListByDocumentPath = '/api/v1/upload/{subFolderId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param folderName undefined
   * @return successful operation
   */
  createFolderResponse(folderName: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (folderName != null) __params = __params.set('folderName', folderName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/upload/createfolder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param folderName undefined
   * @return successful operation
   */
  createFolder(folderName: string): __Observable<string> {
    return this.createFolderResponse(folderName).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `UploadService.CreateSubFolderParams` containing the following parameters:
   *
   * - `subFolderName`:
   *
   * - `parentFolderId`:
   *
   * @return successful operation
   */
  createSubFolderResponse(params: UploadService.CreateSubFolderParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.subFolderName != null) __params = __params.set('subFolderName', params.subFolderName.toString());
    if (params.parentFolderId != null) __params = __params.set('parentFolderId', params.parentFolderId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/upload/createsubfolder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param params The `UploadService.CreateSubFolderParams` containing the following parameters:
   *
   * - `subFolderName`:
   *
   * - `parentFolderId`:
   *
   * @return successful operation
   */
  createSubFolder(params: UploadService.CreateSubFolderParams): __Observable<string> {
    return this.createSubFolderResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param fileId undefined
   * @return successful operation
   */
  deleteFileResponse(fileId: string, fileName: string,connectedUser: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/upload/deletefile/${encodeURIComponent(String(fileId))}/${encodeURIComponent(String(fileName))}/${encodeURIComponent(String(connectedUser))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param fileId undefined
   * @return successful operation
   */
  deleteFile(fileId: string, fileName: string, connectedUser: string): __Observable<string> {
    return this.deleteFileResponse(fileId,fileName,connectedUser).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return successful operation
   */
  doGoogleSignInResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/googlesignin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @return successful operation
   */
  doGoogleSignIn(): __Observable<string> {
    return this.doGoogleSignInResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  saveAuthorizationCodeResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/oauth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  saveAuthorizationCode(): __Observable<null> {
    return this.saveAuthorizationCodeResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UploadService.UploadParams` containing the following parameters:
   *
   * - `subFolderId`:
   *
   * - `newFileName`:
   *
   * - `locationFile`:
   *
   * - `file`:
   *
   * @return successful operation
   */
  uploadResponse(params: UploadService.UploadParams): __Observable<__StrictHttpResponse<{ [key: string]: {} }>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.file != null) __params = __params.set('file', params.file.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/upload/upload/${encodeURIComponent(String(params.subFolderId))}/${encodeURIComponent(String(params.newFileName))}/${encodeURIComponent(String(params.locationFile))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{ [key: string]: {} }>;
      })
    );
  }

  /**
   * @param params The `UploadService.UploadParams` containing the following parameters:
   *
   * - `subFolderId`:
   *
   * - `newFileName`:
   *
   * - `locationFile`:
   *
   * - `file`:
   *
   * @return successful operation
   */
  upload(params: UploadService.UploadParams): __Observable<{ [key: string]: {} }> {
    return this.uploadResponse(params).pipe(
      __map(_r => _r.body as { [key: string]: {} })
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  getListByDocumentResponse(subFolderId: string): __Observable<__StrictHttpResponse<Array<{ [key: string]: {} }>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (subFolderId != null) __params = __params.set('subFolderId', subFolderId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<{ [key: string]: {} }>>;
      })
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  getListByDocument(subFolderId: string): __Observable<Array<{ [key: string]: {} }>> {
    return this.getListByDocumentResponse(subFolderId).pipe(
      __map(_r => _r.body as Array<{ [key: string]: {} }>)
    );
  }

  getListFileByFolderNameResponse(parentFolderId: string, name: string): __Observable<__StrictHttpResponse<Array<{ [key: string]: {} }>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    if (parentFolderId != null) __params = __params.set('parentFolderId', parentFolderId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/byFolderName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<{ [key: string]: {} }>>;
      })
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  getListFileByFolderName(subFolderId: string, name: string): __Observable<Array<{ [key: string]: {} }>> {
    return this.getListFileByFolderNameResponse(subFolderId, name).pipe(
      __map(_r => _r.body as Array<{ [key: string]: {} }>)
    );
  }


  getFilesByFolderNameResponse(parentFolderId: string, name: string): __Observable<__StrictHttpResponse<Array<{ [key: string]: {} }>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    if (parentFolderId != null) __params = __params.set('parentFolderId', parentFolderId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/getFilesByFolderName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<{ [key: string]: {} }>>;
      })
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  getFilesByFolderName(subFolderId: string, name: string): __Observable<Array<{ [key: string]: {} }>> {
    return this.getFilesByFolderNameResponse(subFolderId, name).pipe(
      __map(_r => _r.body as Array<{ [key: string]: {} }>)
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  isFolderExistResponse(folderId: string): __Observable<__StrictHttpResponse<Array<{ [key: string]: {} }>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/${encodeURIComponent(String(folderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<{ [key: string]: {} }>>;
      })
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  isFolderExist(folderId: string): __Observable<Array<{ [key: string]: {} }>> {
    return this.isFolderExistResponse(folderId).pipe(
      __map(_r => _r.body as Array<{ [key: string]: {} }>)
    );
  }

  downloadZipFolderResponse(params: UploadService.DownloadZipParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.files != null) __params = __params.set('files', params.files.toString());
    if (params.parentFolder != null) __params = __params.set('parentFolder', params.parentFolder.toString());
    if (params.candidateName != null) __params = __params.set('candidateName', params.candidateName.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/upload/downloadCustom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param subFolderId undefined
   * @return successful operation
   */
  downloadZipFolder(params: UploadService.DownloadZipParams): __Observable<string> {
    return this.downloadZipFolderResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

}

module UploadService {

  /**
   * Parameters for createSubFolder
   */
  export interface CreateSubFolderParams {
    subFolderName: string;
    parentFolderId: string;
  }

  /**
   * Parameters for upload
   */
  export interface UploadParams {
    subFolderId: string;
    newFileName: string;
    locationFile: string;
    file: any;
  }

  export interface DownloadZipParams {
    files: Array<string>;
    parentFolder: string;
    candidateName: string;
  }

}

export {UploadService}
