/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {ApiConfiguration as __Configuration} from '../api-configuration';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';
import {Observable as __Observable} from 'rxjs';
import {map as __map, filter as __filter} from 'rxjs/operators';

import {UserDto} from '../models/user-dto';
import {ChangeUserPasswordDto} from '../models/change-user-password-dto';

@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly savePath = '/api/v1/users/';
  static readonly getByLoginPath = '/api/v1/users/byLogin/{login}';
  static readonly getAllPath = '/api/v1/users/';
  static readonly changePasswordPath = '/api/v1/users/change-password';
  static readonly getPath = '/api/v1/users/{id}';
  static readonly deletePath = '/api/v1/users/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return successful operation
   */
  saveResponse(body?: UserDto): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * @param body undefined
   * @return successful operation
   */
  save(body?: UserDto): __Observable<UserDto> {
    return this.saveResponse(body).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * @param login undefined
   * @return successful operation
   */
  getByLoginResponse(login: string): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/users/byLogin/${encodeURIComponent(String(login))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * @param login undefined
   * @return successful operation
   */
  getByLogin(login: string): __Observable<UserDto> {
    return this.getByLoginResponse(login).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * @param body undefined
   * @return successful operation
   */
  changePasswordResponse(body?: ChangeUserPasswordDto): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/users/change-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * @param body undefined
   * @return successful operation
   */
  changePassword(body?: ChangeUserPasswordDto): __Observable<UserDto> {
    return this.changePasswordResponse(body).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/users/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  get(id: number): __Observable<UserDto> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  getAllResponse(): __Observable<__StrictHttpResponse<Array<UserDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  getAll(): __Observable<Array<UserDto>> {
    return this.getAllResponse().pipe(
      __map(_r => _r.body as Array<UserDto>)
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  deleteResponse(id: number, connectedUser: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/users/${encodeURIComponent(String(id))}/${encodeURIComponent(String(connectedUser))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  delete(id: number, connectedUser: string): __Observable<string> {
    return this.deleteResponse(id,connectedUser).pipe(
      __map(_r => _r.body as string)
    );
  }

  getConsultantsResponse(): __Observable<__StrictHttpResponse<Array<UserDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/users/consultants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  getConsultants(): __Observable<Array<UserDto>> {
    return this.getConsultantsResponse().pipe(
      __map(_r => _r.body as Array<UserDto>)
    );
  }

  getTeachersResponse(): __Observable<__StrictHttpResponse<Array<UserDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/users/teachers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * @param id undefined
   * @return successful operation
   */
  getTeachers(): __Observable<Array<UserDto>> {
    return this.getTeachersResponse().pipe(
      __map(_r => _r.body as Array<UserDto>)
    );
  }

}

module UserService {
}

export {UserService}
