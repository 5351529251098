import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Message} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationSubject: Subject<Message> = new Subject<Message>();
  notificationState = this.notificationSubject.asObservable();

  constructor() { }

  info(notif: Message) {
    notif.severity = 'info';
    notif.key = 'toast';
    this.notificationSubject.next(notif);
  }

  warning(notif: Message) {
    notif.severity = 'warn';
    notif.key = 'toast';
    this.notificationSubject.next(notif);
  }

  success(notif: Message) {
    notif.severity = 'success';
    notif.key = 'toast';
    this.notificationSubject.next(notif);
  }

  error(notif: Message) {
    notif.severity = 'error';
    notif.key = 'toast';
    this.notificationSubject.next(notif);
  }

  add(notif: Message) {
    notif.key = 'toast';
    if (!notif.severity) {
      notif.severity = 'info';
    }
    this.notificationSubject.next(notif);
  }
}
