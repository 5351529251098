import {Component, OnInit} from '@angular/core';
import {AVATAR_RESOURCES_LIST} from "../models/avatar-resources";
import {DynamicDialogRef} from "primeng/dynamicdialog";


@Component({
  selector: 'app-avatars-container',
  templateUrl: './avatars-container.component.html',
  styleUrls: ['./avatars-container.component.scss']
})
export class AvatarsContainerComponent implements OnInit{

  avatarsList = AVATAR_RESOURCES_LIST

  constructor(
    private ref: DynamicDialogRef
  ){}

  ngOnInit(): void {}

  chooseAvatar(avatar) {
    this.ref.close(avatar);
  }
}
